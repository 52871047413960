import styled, { keyframes } from "styled-components";
const clickAnimation = keyframes`
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.95);
  }
`;
export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
  background-color: lightblue;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

export const FormInput = styled.input`
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s ease;
  font-weight: 500;
  font-size: 20px

  &:focus {
    border-color: #007bff;
  }
`;

export const SubmitButton = styled.button`
  background-color: #45c2e4;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 10px;
  font-weight: bold;
  animation: ${clickAnimation} 0.3s ease;

  &:hover {
    background-color: blue;
  }
`;
export const Label = styled.label`
  font-weight: bold;
  font-size: 14px;
  color: #282c34;
`;

export const CenteredItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const Result = styled.p`
  font-weight: 600;
  font-size: 20px;
  color: blue;
`;
