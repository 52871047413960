import React, { useState } from "react";
import {
  FormContainer,
  FormInput,
  SubmitButton,
  CenteredItems,
  Result,
  Label,
} from "./FormSyled";
import CustomDatePicker from "./DatePicker";
import dayjs from "dayjs";

const Form = () => {
  const [zapremina, setZapremina] = useState(0);
  const [snaga, setSnaga] = useState(0);
  const [datum, setDatum] = useState(dayjs());
  const [number, setNumber] = useState(0);
  const [koeficijent, setKoeficijent] = useState(0);
  const [ageDif, setAgeDif] = useState(0);

  const handleDateChange = (selectedDate) => {
    setDatum(selectedDate);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formattedDatum = datum.format("DD-MM-YYYY");
    const today = dayjs();
    const ageDifference = today.diff(datum, "year");

    console.log("Radna zapremina:", zapremina);
    console.log("Snaga motora:", snaga);
    console.log("Datum:", formattedDatum);
    console.log("Age Difference:", ageDifference);
    setAgeDif(ageDifference);
    var broj = 0;
    if (ageDifference >= 10) {
      broj = 0.3;
    } else if (ageDifference === 9) {
      broj = 0.35;
    } else if (ageDifference === 8) {
      broj = 0.4;
    } else if (ageDifference === 7) {
      broj = 0.45;
    } else if (ageDifference === 6) {
      broj = 0.5;
    } else if (ageDifference === 5) {
      broj = 0.6;
    } else if (ageDifference === 4) {
      broj = 0.7;
    } else if (ageDifference === 3) {
      broj = 0.8;
    } else if (ageDifference === 2) {
      broj = 0.9;
    } else broj = 1;
    setKoeficijent(broj);

    const calculated = (zapremina * 320 + snaga * 6400) * broj;
    console.log("konacni koeficijent ", calculated);
    const putDots = (number) => {
      const parts = String(number).split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");

      return parts.join(".");
    };
    setNumber(putDots(calculated));
  };

  return (
    <FormContainer>
      <form onSubmit={handleSubmit}>
        <CenteredItems>
          <Label>Radna zapremina vozila:</Label>
          <FormInput
            required
            type="number"
            placeholder="Unesite zapreminu"
            onChange={(e) => setZapremina(e.target.value)}
          />
          <Label>Snaga motora:</Label>
          <FormInput
            required
            type="number"
            placeholder="Unesite snagu"
            onChange={(e) => setSnaga(e.target.value)}
          />
          <Label>Datum registracije:</Label>
          <CustomDatePicker onChange={handleDateChange} />
          <SubmitButton type="submit">Obračunaj</SubmitButton>
        </CenteredItems>
      </form>
      {number ? <Result>{number} RSD</Result> : <></>}
      {koeficijent ? (
        <Result>Koeficijent starosti vozila {koeficijent * 100}%</Result>
      ) : (
        <></>
      )}
      {ageDif ? <Result>Vozilo je staro : {ageDif} godina/e</Result> : <></>}
    </FormContainer>
  );
};

export default Form;
